


















































































import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { db, auth } from "@/plugins/firebase";
import { mdiAccount } from "@mdi/js";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import { notificationStatus } from "@/options/notificationOptions";

export default Vue.extend({
  name: "Email",
  data() {
    return {
      emailTo: "",
      allUsers: [],
      emailSearch: "",
      emailLoading: false,
      loading: false,
      showMsg: false,
      message: "",
      svgAccount: mdiAccount
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard"]),
    ...mapGetters("users", ["email", "uid"]),
    me(): boolean {
      return this.emailTo === this.email;
    }
  },
  watch: {
    emailSearch(search) {
      if (!search || !search.trim()) {
        return;
      }
      if (search === this.emailTo) return;
      this.emailTo = search;
    }
  },
  methods: {
    ...mapActions("notifications", ["notification"]),
    async sendInvite() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;

      // NOT WORKING
      // const message = functions().httpsCallable("sendEmail");
      // message({
      //   email: this.emailTo,
      //   emailFrom: this.email,
      //   boardUrl: `${window.location.origin}/${this.$route.name}/${this.board.id}`,
      //   boardName: this.board.name
      // }).then(res => {
      //   if (res.status === 200) {
      //     this.$store.dispatch("boards/updateBoard", {
      //       id: this.board.id,
      //       viewers: [...this.board.viewers, this.emailTo]
      //     });
      //   }
      // });

      let token = "";
      await auth.currentUser
        ?.getIdToken(/* forceRefresh */ true)
        .then(function(idToken: string) {
          token = idToken;
        });

      const url = `${process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST}/app/sendEmail`;

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          email: this.emailTo,
          emailFrom: this.email,
          boardUrl: `${window.location.origin}/auth?redirectPath=${this.$route.name}/${this.currentBoard.id}`, // ${this.$route.hash || ""}
          boardName: this.currentBoard.name,
          message: this.message.split("\n").join("<br>")
        })
      })
        .then(res => {
          if (res.status === 200) {
            this.notification({
              ...notificationStatus.SUCCESS,
              message: this.$t("share.email_sent")
            });

            analyticsLogEvent(gaEventNames.board_shared, {
              board_id: this.currentBoard.id,
              board_user_id: this.currentBoard.user,
              board_share_type: "email"
            });

            if (!this.currentBoard.viewers.includes(this.emailTo)) {
              db.collection(`boards`)
                .doc(this.currentBoard.id)
                .update({
                  viewers: [...this.currentBoard.viewers, this.emailTo]
                });
            }
          } else {
            this.notification({
              ...notificationStatus.ERROR,
              message: this.$t("share.email_sent_error")
            });
          }
        })
        .catch(() =>
          this.notification({
            ...notificationStatus.ERROR,
            message: this.$t("share.email_sent_error")
          })
        )
        .finally(() => this.clear());
    },
    async removeViewer(viewer: string) {
      if (await this.$confirm("Are you sure?")) {
        analyticsLogEvent(gaEventNames.board_share_cancel, {
          board_id: this.currentBoard.id,
          board_share_type: "email",
          board_user_id: this.currentBoard.user
        });

        await db
          .collection("boards")
          .doc(this.currentBoard.id)
          .update({
            viewers: this.currentBoard.viewers.filter(
              (v: string) => v !== viewer
            )
          });
      }
    },
    clear() {
      this.$v.$reset();
      this.emailTo = "";
      this.showMsg = false;
      this.message = "";
      this.loading = false;
    }
  },
  validations() {
    return {
      emailTo: { required, email }
    };
  }
});
