

































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { db, auth } from "@/plugins/firebase";
import firebase from "firebase/app";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import { notificationStatus } from "@/options/notificationOptions";

const accessTypes = [
  { label: "No access", private: true },
  { label: "Can view", private: false }
];

export default Vue.extend({
  name: "Link",
  components: {
    BoardPassword: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Boards/BoardPasswordForm.vue"
      )
  },
  data() {
    return {
      password: "",
      psswdModal: false,
      loading: false,
      accessTypes: accessTypes
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard"]),
    link(): string {
      const url = window.location.host;
      return `${window.location.protocol}//${url}/boards/${this.currentBoard.id}`;
    },
    hasPassword(): boolean {
      return "hash" in this.currentBoard;
    }
  },
  methods: {
    ...mapActions("notifications", ["notification"]),
    changePermission(isPrivate: boolean) {
      const properties = {
        board_id: this.currentBoard.id,
        board_user_id: this.currentBoard.user,
        board_share_type: "link"
      } as gaEventNames.AnalyticsEventProperties;

      if (isPrivate) {
        analyticsLogEvent(gaEventNames.board_share_cancel, properties);
      } else {
        analyticsLogEvent(gaEventNames.board_shared, properties);
      }

      db.collection("boards")
        .doc(this.currentBoard.id)
        .update({ private: isPrivate });
    },
    deletePassword() {
      db.collection("boards")
        .doc(this.currentBoard.id)
        .update({ hash: firebase.firestore.FieldValue.delete() });
    },
    async copyLink() {
      await navigator.clipboard.writeText(this.link);
      this.notification({
        ...notificationStatus.INFO,
        message: this.$t("share.link_copied")
      });
    },
    async setPassword(psswd: string) {
      this.loading = true;
      let token;
      await auth.currentUser
        ?.getIdToken(/* forceRefresh */ true)
        .then(function(idToken) {
          token = idToken;
        });

      const url = `${process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST}/app/setBoardPassword`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          id: this.currentBoard.id,
          password: psswd
        })
      })
        .then(res => res.json())
        .then(res => {
          this.loading = false;
          this.psswdModal = false;
        });
    }
  }
});
