<template>
  <v-row v-if="currentBoard && owner">
    <v-tabs v-if="tabsVisible" v-model="tab" color="black" :show-arrows="false">
      <v-tab color="black">
        {{ $t("share.selection") }}
      </v-tab>
      <v-tab data-testid="entire_board" color="black">
        {{ $t("share.entire_board") }}
      </v-tab>
      <div class="line-group" />
    </v-tabs>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <keep-alive>
          <ShareVisionForm
            :data="currentBoardConfig"
            @close="$emit('close')"
            @onStepChange="onStepChange"
          />
        </keep-alive>
      </v-tab-item>
      <v-tab-item>
        <keep-alive>
          <div>
            <v-col cols="12">
              <Link />
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <Email />
            </v-col>
          </div>
        </keep-alive>
      </v-tab-item>
    </v-tabs-items>
  </v-row>
</template>
<script>
import mixins from "vue-typed-mixins";
import { mapState } from "vuex";
import Email from "@/components/Invitations/Email";
import Link from "@/components/Invitations/Link";
import userMixin from "@/mixins/user";
import ShareVisionForm from "@/components/Forms/Boards/ShareVisionForm";

export default mixins(userMixin).extend({
  name: "BoardShareForm",
  components: { ShareVisionForm, Link, Email },
  data() {
    return {
      tab: null,
      tabsVisible: true,
      step: 1
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard", "currentBoardConfig"])
  },
  watch: {
    "currentBoard.id"(val) {
      if (val) {
        this.isOwner(val);
      }
    }
  },
  mounted() {
    this.isOwner(this.currentBoard?.id);
  },
  methods: {
    onStepChange(val) {
      this.tabsVisible = val <= 2;
    }
  }
});
</script>

<style scoped lang="scss">
::v-deep .v-slide-group__wrapper {
  border-bottom: 2px solid #fff;
}
::v-deep .v-tab {
  margin-left: 0 !important;
  font-size: 16px;
  font-weight: 500;
  align-items: flex-end;
  padding-bottom: 5px;
  text-transform: none;
  letter-spacing: 0px;
}

::v-deep .v-tab--active {
  color: $primary;
  font-weight: bold;
}
::v-deep .v-slide-group__wrapper {
  border-color: transparent;
}

.line-group {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-bottom: 2px solid rgba(9, 16, 51, 0.2);
}

::v-deep .v-tabs-slider-wrapper {
  color: $primary;
}

::v-deep .v-tabs-items,
::v-deep .v-window {
  width: 100% !important;
}

@media only screen and (min-width: 959px) {
  ::v-deep .v-tab {
    margin-left: 0 !important;
    font-size: 0.875rem;
  }
}

::v-deep .v-card__text {
  padding: 0 !important;
}

::v-deep .v-slide-group__prev {
  display: none !important;
}
</style>
