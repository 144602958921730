import { render, staticRenderFns } from "./Link.vue?vue&type=template&id=20ec7ffc&scoped=true&"
import script from "./Link.vue?vue&type=script&lang=ts&"
export * from "./Link.vue?vue&type=script&lang=ts&"
import style0 from "./Link.vue?vue&type=style&index=0&id=20ec7ffc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ec7ffc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCol,VRow,VSubheader})
