<template>
  <div>
    <v-stepper v-model="step">
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="spinner--wrapper">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div class="d-flex justify-center align-center">
            <div
              class="step mb-md-6 mb-sm-6 mt-md-6 mt-sm-1 black--text font-weight-bold"
              v-html="$t('share.selection_steps.step_1')"
            />
          </div>
          <v-row class="fields--row mx-0">
            <v-col v-if="imagesBlock" cols="12" sm="6">
              <div
                class="field--wrapper fs-block"
                :class="{
                  checked: isFieldSelected('images_field')
                }"
                @click="selectField('images_field')"
              >
                <div>
                  <BaseEditor
                    :style="{ paddingLeft: '25px' }"
                    :content.sync="imagesBlock.title"
                    :editable="false"
                    :blank="true"
                  />
                  <img
                    v-if="imagesDataUrl"
                    :src="imagesDataUrl"
                    alt="images_field"
                    style="width: 100%"
                  />
                </div>
                <BaseButtonIcon
                  v-if="isFieldSelected('images_field')"
                  class="icon--uncheck"
                >
                  <Icon color="white">{{ svgCheck }}</Icon>
                </BaseButtonIcon>
              </div>
            </v-col>
            <v-col
              v-for="(block, $index) in fields"
              :key="$index"
              cols="12"
              sm="6"
            >
              <div
                class="field--wrapper"
                :class="{
                  checked: isFieldSelected(block.assignment)
                }"
                @click="selectField(block.assignment)"
              >
                <ImagesTemplate
                  v-if="block.type === 'images'"
                  :id="`__${block.assignment}`"
                  :key="$index"
                  class="fs-block"
                  :block="block"
                />
                <EditorWrapper
                  v-else
                  :id="`__${block.assignment}`"
                  :key="$index"
                  class="fs-mask"
                  :title-styles="{ paddingLeft: '25px' }"
                  :block="block"
                  :editable="false"
                  style="display:grid; height: 100%; width: 100%"
                ></EditorWrapper>
                <BaseButtonIcon
                  v-if="isFieldSelected(block.assignment)"
                  class="icon--uncheck"
                >
                  <Icon style="width: 13px; height: 13px;" color="white">{{
                    svgCheck
                  }}</Icon>
                </BaseButtonIcon>
              </div>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="fields--wrapper">
            <div
              class="d-flex justify-center align-center relative"
              :class="{
                'flex-column': isMobile
              }"
            >
              <div
                class="step mb-md-6 mb-sm-6 mt-md-6 mt-sm-1 black--text font-weight-bold"
                v-html="$t('share.selection_steps.step_2')"
              />
            </div>
            <v-row class="images--row">
              <v-col
                v-if="isFieldSelected('images_field')"
                cols="12"
                sm="6"
                md="3"
                class="ma-1 d-flex flex-column justify-center"
              >
                <div style="height: 100%" class="img--border">
                  <img
                    class="img--preview fs-block pt-2"
                    :src="imagesDataUrl"
                    alt="images_field"
                  />
                </div>
                <BaseButton
                  class="mt-2"
                  text
                  :disabled="!picturesLoaded"
                  @click="
                    downloadSingleImage({
                      field: 'images_field',
                      dataUrl: imagesDataUrl
                    })
                  "
                >
                  <Icon
                    small
                    tooltip="Download this image"
                    icon-class="mr-1 pb-1"
                  >
                    vb-download
                  </Icon>
                  {{ $t("share.download") }}
                </BaseButton>
              </v-col>
              <v-col
                v-for="(block, $index) in imagesToDownload"
                :key="$index"
                cols="12"
                sm="6"
                md="3"
                class="ma-1 d-flex flex-column justify-center"
              >
                <div style="height: 100%" class="img--border">
                  <img
                    v-if="isFieldSelected(block.field)"
                    :src="block.dataUrl"
                    class="img--preview fs-block pt-2"
                    :alt="block.field"
                  />
                </div>
                <BaseButton
                  class="mt-2"
                  text
                  :disabled="!picturesLoaded"
                  @click="downloadSingleImage(block)"
                >
                  <Icon
                    small
                    tooltip="Download this image"
                    icon-class="mr-1 pb-1"
                  >
                    vb-download
                  </Icon>
                  {{ $t("share.download") }}
                </BaseButton>
              </v-col>
            </v-row>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div
            class="d-flex justify-center align-center relative"
            :class="{
              'flex-column': isMobile
            }"
          >
            <BaseButton
              v-if="step > 1"
              color="black"
              text
              class="btn--back"
              @click="step--"
            >
              <Icon icon-class="mr-1"> {{ svgKeyboardBackspace }} </Icon>
              {{ $t("common.back") }}
            </BaseButton>
            <div
              class="step mb-md-6 mb-sm-6 mt-md-6 mt-sm-1 black--text font-weight-bold text-center"
              v-html="$t('share.selection_steps.step_3')"
            />
          </div>
          <div class="d-flex justify-center">
            <BaseButtonIcon
              class="btn--share btn--instagram"
              href="https://www.instagram.com/"
              target="_blank"
              large
            >
              <Icon color="white">{{ svgInstagram }}</Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="btn--share btn--facebook"
              href="https://www.facebook.com/"
              target="_blank"
              large
            >
              <Icon color="white">{{ svgFacebook }}</Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="btn--share btn--whatsapp"
              href="https://www.whatsapp.com/"
              target="_blank"
              large
            >
              <Icon color="white">{{ svgWhatsapp }}</Icon>
            </BaseButtonIcon>
          </div>
          <div class="text-h5 ml-auto stepper--nav text-right">
            <BaseButton
              :disabled="!picturesLoaded"
              color="primary"
              class="ml-auto"
              @click="close"
            >
              Done
            </BaseButton>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div
      v-if="step !== 3"
      class="text-h5 ml-auto stepper--nav text-right"
      style="cursor:pointer; position: sticky"
    >
      <BaseButton class="ml-auto mr-2 btn--discard" outlined @click="close">
        {{ $t("share.discard") }}
      </BaseButton>
      <BaseButton :disabled="!picturesLoaded" color="primary" @click="step++">
        <span v-if="picturesLoaded">
          {{ $t("common.next_step_alt") }}
        </span>
        <span v-else>Generating pictures...</span>
      </BaseButton>
    </div>
  </div>
</template>

<script>
// TODO add ts (BlockInitialAssignments type, etc.)
import { toPng } from "html-to-image";
import ImagesTemplate from "@/components/Images/ImagesTemplate";
import { createCanvasImage } from "@/helpers/imageHelpers";
import {
  mdiInstagram,
  mdiWhatsapp,
  mdiFacebook,
  mdiDownload,
  mdiCheck,
  mdiKeyboardBackspace
} from "@mdi/js";
import BaseEditor from "@/components/Editors/BaseEditor";
import { db } from "@/plugins/firebase";
import { mapActions, mapState } from "vuex";

const shareableFields = ["mission_statement", "daily_routine"];
export default {
  name: "ShareVisionForm",
  components: {
    BaseEditor,
    ImagesTemplate,
    EditorWrapper: () =>
      import(/* webpackPrefetch: true */ "@/components/Editors/EditorWrapper")
  },
  props: {
    data: {
      type: Object
    }
  },
  data: () => ({
    imageSources: [],
    step: 1,
    picturesLoaded: false,
    shareableFields: [...shareableFields],
    selectedFields: ["images_field", ...shareableFields],
    imagesFieldDataUrl: "",
    svgInstagram: mdiInstagram,
    svgFacebook: mdiFacebook,
    svgWhatsapp: mdiWhatsapp,
    svgDownload: mdiDownload,
    svgCheck: mdiCheck,
    svgKeyboardBackspace: mdiKeyboardBackspace,
    layoutCanvasOptions: undefined
  }),
  computed: {
    ...mapState("boards", ["currentBoard", "updateInProgress"]),
    config() {
      return this.data;
    },
    imagesDataUrl() {
      return this.data.thumbnail;
    },
    imagesBlock() {
      const imagesBlock = this.data.blocks.filter(
        elem => elem.type === "images"
      );

      return imagesBlock ? imagesBlock[0] : null;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    imagesToDownload() {
      return this.imageSources.filter(elem => this.isFieldSelected(elem.field));
    },
    fields() {
      if ("blocks" in this.config) {
        return this.config.blocks.filter(bl =>
          this.shareableFields.includes(bl.assignment)
        );
      }
      return [];
    }
  },
  watch: {
    picturesLoaded(val) {
      if (val) {
        this.step++;
      }
    },
    step(val) {
      this.$emit("onStepChange", val);
    }
  },
  async mounted() {
    this.$nextTick(function() {
      setTimeout(() => {
        this._drawPictures();
        this._getBoardCanvasOptions();
      }, 0);
    });
  },
  methods: {
    ...mapActions("notifications", ["notification"]),
    close() {
      this.$emit("close");
    },
    downloadSingleImage(src) {
      const name = src.field;
      const link = document.createElement("a");
      link.href = src.dataUrl;
      link.download = name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    selectField(field) {
      if (this.isFieldSelected(field)) {
        const index = this.selectedFields.findIndex(elem => elem === field);
        this.selectedFields.splice(index, 1);
      } else {
        this.selectedFields.push(field);
      }
    },
    isFieldSelected(field) {
      return this.selectedFields.includes(field);
    },
    async download() {
      const sleep = ms => new Promise(res => setTimeout(res, ms));

      const element = {
        dataUrl: this.imagesFieldDataUrl,
        field: "images_field"
      };

      const arr = [element, ...this.imageSources];
      for (let i = 0; i < arr.length; i++) {
        const src = arr[i];
        await sleep(1000);
        if (this.isFieldSelected(src.field)) {
          const name = src.field;
          const link = document.createElement("a");
          link.href = src.dataUrl;
          link.download = name;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      }
    },
    async _getBoardCanvasOptions() {
      if (this.currentBoard?.layoutId) {
        const template = await db
          .collection("layouts")
          .doc(this.currentBoard.layoutId)
          .get();
        const componentName = template.data()?.component;
        if (componentName) {
          this.layoutComponent = componentName;
        }
        const options = template.data()?.canvasOptions;
        if (options) {
          this.layoutCanvasOptions = options;
        }

        await this._drawImagesFieldCanvas();
      }
    },
    async _drawImagesFieldCanvas() {
      const images = this.imagesBlock.images.map(
        image =>
          new Promise((resolve, reject) => {
            // Resolve source and img when loaded
            const img = new Image();
            img.onerror = err => reject(new Error(err));
            img.onload = function() {
              resolve(Object.assign({}, image, { img }));
            };
            img.src = image.src;
          })
      );
      createCanvasImage(images, this.layoutCanvasOptions).then(b64 => {
        this.imagesFieldDataUrl = b64;
      });
    },
    async _drawPictures() {
      const imageSources = [];

      this.picturesLoaded = false;
      for (const field of this.shareableFields) {
        const node = document.getElementById(`__${field}`);
        const dataUrl = await toPng(node);
        imageSources.push({
          field,
          dataUrl
        });
      }

      this.imageSources = imageSources;
      this.picturesLoaded = true;
    }
  }
};
</script>

<style scoped lang="scss">
.images {
  display: flex;
  background: var(--v-gray-lighten4);
}

.spinner--wrapper {
  width: 100%;
  background: rgb(255, 255, 255);
  position: absolute;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn--share {
  width: 38px;
  height: 38px;
  margin: 0 16px 0 0;
  padding: 9px;
}

.btn--discard {
  color: var(--v-gray-darken2) !important;
}

.btn--instagram {
  background-image: linear-gradient(
    to bottom,
    #833ab4,
    #fd1d1d 46%,
    #fcb045 88%
  );
}

.icon--uncheck {
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 999px;
  color: var(--v-gray-lighten4) !important;
  background: $primary;
  padding: 0.425rem 0.625rem;
}

.btn--facebook {
  background-color: #3b5998;
}

.btn--whatsapp {
  background-color: #25d366;
}

.images img {
  max-width: 100%;
  padding: 1rem;
  box-shadow: 0px 0px 24px -1px $gray;
}

.step {
  font-size: 16px;
}

.fields--wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-bottom: 66px;
}

.field--wrapper {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: 0.5px solid #a4a4a4;
  padding: 2px;
  transition: 0.05s padding ease-out;
  min-height: 346px;
  height: 100%;
}

.field--wrapper.checked {
  border: 2px solid $primary;
  padding: 2px;
  transition: 0.05s padding ease-out;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-stepper {
  box-shadow: none !important;
}

::v-deep .v-window {
  width: 100% !important;
}

::v-deep .v-tabs-items,
::v-deep .v-window {
  width: 100% !important;
}

::v-deep .v-stepper__content {
  padding: 0 !important;
}

.stepper--nav {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.relative {
  position: relative;
}

.img--border {
  border-radius: 9px;
  border: 1px solid $gray;
}

.img--preview {
  width: auto;
  height: 200px;
}

.images--row {
  flex-direction: row;
  padding: 10px 15px;
  overflow-x: scroll;
  flex-wrap: nowrap;
}

.fields--row {
  min-width: 100%;
  flex-direction: row;
  overflow-x: scroll;
  flex-wrap: nowrap;
}

.fields--row > div {
  min-width: 360px;
}

.btn--back {
  position: static;
}

::v-deep .icon--uncheck svg {
  width: 13px;
  height: 13px;
}

@media (max-width: 959px) {
  .text-h4,
  .text-h5 {
    font-size: 1.25rem !important;
  }
}

@media only screen and (min-width: 1264px) {
  .stepper--nav {
    position: static;
    padding: 0;
  }
}

@media only screen and (min-width: 959px) {
  .img--preview {
    height: auto;
    width: 100%;
  }
  .stepper--nav {
    position: static;
    padding: 0;
  }
  .btn--back {
    position: absolute;
    left: 0;
  }
  .images--row {
    min-width: 100%;
    flex-direction: row;
    overflow-x: auto;
    display: flex;
    justify-content: center;
  }

  .fields--row {
    min-width: 100%;
    flex-direction: row;
  }

  .field--wrapper {
    min-height: 346px;
  }
}
</style>
