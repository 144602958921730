var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-stepper',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('div',{staticClass:"spinner--wrapper"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"step mb-md-6 mb-sm-6 mt-md-6 mt-sm-1 black--text font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.$t('share.selection_steps.step_1'))}})]),_c('v-row',{staticClass:"fields--row mx-0"},[(_vm.imagesBlock)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"field--wrapper fs-block",class:{
                checked: _vm.isFieldSelected('images_field')
              },on:{"click":function($event){return _vm.selectField('images_field')}}},[_c('div',[_c('BaseEditor',{style:({ paddingLeft: '25px' }),attrs:{"content":_vm.imagesBlock.title,"editable":false,"blank":true},on:{"update:content":function($event){return _vm.$set(_vm.imagesBlock, "title", $event)}}}),(_vm.imagesDataUrl)?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.imagesDataUrl,"alt":"images_field"}}):_vm._e()],1),(_vm.isFieldSelected('images_field'))?_c('BaseButtonIcon',{staticClass:"icon--uncheck"},[_c('Icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.svgCheck))])],1):_vm._e()],1)]):_vm._e(),_vm._l((_vm.fields),function(block,$index){return _c('v-col',{key:$index,attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"field--wrapper",class:{
                checked: _vm.isFieldSelected(block.assignment)
              },on:{"click":function($event){return _vm.selectField(block.assignment)}}},[(block.type === 'images')?_c('ImagesTemplate',{key:$index,staticClass:"fs-block",attrs:{"id":("__" + (block.assignment)),"block":block}}):_c('EditorWrapper',{key:$index,staticClass:"fs-mask",staticStyle:{"display":"grid","height":"100%","width":"100%"},attrs:{"id":("__" + (block.assignment)),"title-styles":{ paddingLeft: '25px' },"block":block,"editable":false}}),(_vm.isFieldSelected(block.assignment))?_c('BaseButtonIcon',{staticClass:"icon--uncheck"},[_c('Icon',{staticStyle:{"width":"13px","height":"13px"},attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.svgCheck))])],1):_vm._e()],1)])})],2)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('div',{staticClass:"fields--wrapper"},[_c('div',{staticClass:"d-flex justify-center align-center relative",class:{
              'flex-column': _vm.isMobile
            }},[_c('div',{staticClass:"step mb-md-6 mb-sm-6 mt-md-6 mt-sm-1 black--text font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.$t('share.selection_steps.step_2'))}})]),_c('v-row',{staticClass:"images--row"},[(_vm.isFieldSelected('images_field'))?_c('v-col',{staticClass:"ma-1 d-flex flex-column justify-center",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"img--border",staticStyle:{"height":"100%"}},[_c('img',{staticClass:"img--preview fs-block pt-2",attrs:{"src":_vm.imagesDataUrl,"alt":"images_field"}})]),_c('BaseButton',{staticClass:"mt-2",attrs:{"text":"","disabled":!_vm.picturesLoaded},on:{"click":function($event){return _vm.downloadSingleImage({
                    field: 'images_field',
                    dataUrl: _vm.imagesDataUrl
                  })}}},[_c('Icon',{attrs:{"small":"","tooltip":"Download this image","icon-class":"mr-1 pb-1"}},[_vm._v(" vb-download ")]),_vm._v(" "+_vm._s(_vm.$t("share.download"))+" ")],1)],1):_vm._e(),_vm._l((_vm.imagesToDownload),function(block,$index){return _c('v-col',{key:$index,staticClass:"ma-1 d-flex flex-column justify-center",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"img--border",staticStyle:{"height":"100%"}},[(_vm.isFieldSelected(block.field))?_c('img',{staticClass:"img--preview fs-block pt-2",attrs:{"src":block.dataUrl,"alt":block.field}}):_vm._e()]),_c('BaseButton',{staticClass:"mt-2",attrs:{"text":"","disabled":!_vm.picturesLoaded},on:{"click":function($event){return _vm.downloadSingleImage(block)}}},[_c('Icon',{attrs:{"small":"","tooltip":"Download this image","icon-class":"mr-1 pb-1"}},[_vm._v(" vb-download ")]),_vm._v(" "+_vm._s(_vm.$t("share.download"))+" ")],1)],1)})],2)],1)]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('div',{staticClass:"d-flex justify-center align-center relative",class:{
            'flex-column': _vm.isMobile
          }},[(_vm.step > 1)?_c('BaseButton',{staticClass:"btn--back",attrs:{"color":"black","text":""},on:{"click":function($event){_vm.step--}}},[_c('Icon',{attrs:{"icon-class":"mr-1"}},[_vm._v(" "+_vm._s(_vm.svgKeyboardBackspace)+" ")]),_vm._v(" "+_vm._s(_vm.$t("common.back"))+" ")],1):_vm._e(),_c('div',{staticClass:"step mb-md-6 mb-sm-6 mt-md-6 mt-sm-1 black--text font-weight-bold text-center",domProps:{"innerHTML":_vm._s(_vm.$t('share.selection_steps.step_3'))}})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('BaseButtonIcon',{staticClass:"btn--share btn--instagram",attrs:{"href":"https://www.instagram.com/","target":"_blank","large":""}},[_c('Icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.svgInstagram))])],1),_c('BaseButtonIcon',{staticClass:"btn--share btn--facebook",attrs:{"href":"https://www.facebook.com/","target":"_blank","large":""}},[_c('Icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.svgFacebook))])],1),_c('BaseButtonIcon',{staticClass:"btn--share btn--whatsapp",attrs:{"href":"https://www.whatsapp.com/","target":"_blank","large":""}},[_c('Icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.svgWhatsapp))])],1)],1),_c('div',{staticClass:"text-h5 ml-auto stepper--nav text-right"},[_c('BaseButton',{staticClass:"ml-auto",attrs:{"disabled":!_vm.picturesLoaded,"color":"primary"},on:{"click":_vm.close}},[_vm._v(" Done ")])],1)])],1)],1),(_vm.step !== 3)?_c('div',{staticClass:"text-h5 ml-auto stepper--nav text-right",staticStyle:{"cursor":"pointer","position":"sticky"}},[_c('BaseButton',{staticClass:"ml-auto mr-2 btn--discard",attrs:{"outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("share.discard"))+" ")]),_c('BaseButton',{attrs:{"disabled":!_vm.picturesLoaded,"color":"primary"},on:{"click":function($event){_vm.step++}}},[(_vm.picturesLoaded)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("common.next_step_alt"))+" ")]):_c('span',[_vm._v("Generating pictures...")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }